#root {
	width: 100%;
	height: 100%;
}

br + br {
	display: none;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
	outline: #000 dotted 2px;
	box-shadow: none;
	outline-offset: 0.05em;
}
